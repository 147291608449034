const cartCreditDefaultUrl = require('../assets/images/icon-credit-card@3x.png');
const cartCreditGreenUrl = require('../assets/images/icon-credit-card@3x.png');

const cartClientDefaultUrl = require('../assets/images/icon-client-card@3x.png');
const cartClientGreenUrl = require('../assets/images/icon-client-card@3x.png');

const indexProductScanDefaultUrl = require('../assets/images/index-product-scan-blue.png');
const indexProductScanGreenUrl = require('../assets/images/index-product-scan-blue.png');

export default {
  iCartCredit: {
    defaultUrl: cartCreditDefaultUrl,
    greenUrl: cartCreditGreenUrl,
  },
  iCartClient: {
    defaultUrl: cartClientDefaultUrl,
    greenUrl: cartClientGreenUrl,
  },
  iIndexProductScan: {
    defaultUrl: indexProductScanDefaultUrl,
    greenUrl: indexProductScanGreenUrl,
  },
};
