var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-payment-methods"},[_c('h1',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.paymentmethods.headline'))}}),(_vm.paymentCategories)?_c('div',{staticClass:"payment-methods"},_vm._l((_vm.paymentCategories),function(paymentCategory,paymentCategoryIndex){return _c('div',{key:`payment-category-${paymentCategoryIndex}`,class:[
        'payment-method',
        { 'pressed' : _vm.pressed === paymentCategoryIndex }
      ],on:{"touchstart":function($event){return _vm.touchStart($event, paymentCategoryIndex)},"touchend":function($event){return _vm.touchEnd($event, paymentCategoryIndex);},"touchcancel":function($event){return _vm.touchEnd($event, paymentCategoryIndex)}}},[(paymentCategory.name === 'InApp')?_c('router-link',{staticClass:"f-link",attrs:{"to":{ path: '/cart/app-payment',
          query: {
            activeStep: 2,
            paymentCategoryID: paymentCategory.id,
            paymentCategoryName: paymentCategory.name
          }
        }}}):_vm._e(),(paymentCategory.name === 'InShop')?_c('router-link',{staticClass:"f-link",attrs:{"to":{ path: '/cart/checkout',
          query: {
            paymentCategoryID: paymentCategory.id,
            paymentCategoryName: paymentCategory.name
          }
        }}}):_vm._e(),_c('div',{staticClass:"method-inner"},[_c('div',{staticClass:"method-image"},[(paymentCategory.name === 'InApp')?_c('img',{attrs:{"src":_vm.iconClientCard,"alt":"$t('terminal.cart.paymentmethods.methods.client.headline')"}}):_vm._e(),(paymentCategory.name === 'InShop')?_c('img',{attrs:{"src":_vm.iconCreditCard,"alt":"$t('terminal.cart.paymentmethods.methods.credit.headline')"}}):_vm._e()]),(paymentCategory.name === 'InApp')?_c('div',{staticClass:"method-headline",domProps:{"innerHTML":_vm._s('Mit der App bezahlen')}}):_vm._e(),(paymentCategory.name === 'InShop')?_c('div',{staticClass:"method-headline",domProps:{"innerHTML":_vm._s('Mit EC Karte')}}):_vm._e()])],1)}),0):_vm._e(),_c('div',{staticClass:"help-ec"},[_c('div',{staticClass:"help-ec-inner"},[_c('div',{staticClass:"actions"},[_c('div',{staticClass:"abort",on:{"click":function($event){return _vm.resetStoreCart()}}},[_c('span',{domProps:{"innerHTML":_vm._s('Einkauf abbrechen')}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }